import Select from 'react-select';
//import { useRef } from "react";
import React, { useState } from 'react';
import YouTube from 'react-youtube';
const Miracles = () => {
  
  const miraclesvideoOptions = [
    {
      id: '93cqR-nwI8s',
      description: '3 Scientifically Proven Eucharistic Miracles',
    },
    {
      id: 'vxIgBwZgOGU',
      description: 'Top 10 Miracles That Can\'t Be Explained',
    },
    {
      id: 'xntkCxzu3Vk',
      description: '4 Unbelievable Miracles From Praying The Rosary',
    },
    {
      id: 'tpB8koNZB9Q',
      description: '4 Mind-blowing Miracles From Mary\'s Intercession',
    },
    // Add more video options here 
  ];
     
      //const renderAfterCalled = useRef(false);
      const opts = {
        height: '300', // or a percentage value (e.g., '100%')
        width: '390', // or a percentage value (e.g., '100%')
        playerVars: {
          autoplay: 0,
          controls: 1,
        },
      };
    
      const [miraclesselectedVideo, setmiraclesSelectedVideo] = useState(miraclesvideoOptions[0].id);

      const handlemiraclesVideoChange = (event) => {
    
    
        const selectedVideoId = event.id;
        setmiraclesSelectedVideo(selectedVideoId);
      };
    return (
     <div>
          
      <br />
      <b><u> <p>Select a video on miracles</p></u></b>
      
      <div className='card'>
      <div className="centered">
        <Select
          menuPlacement="auto"
          menuPosition="fixed"
          className="basic-single"
          classNamePrefix="select"
          isSearchable={true}
          defaultValue={miraclesvideoOptions[0]}
          name="Video"
          options={miraclesvideoOptions}
          onChange={handlemiraclesVideoChange}
          getOptionValue={(miraclesvideoOptions) => miraclesvideoOptions.id}
          getOptionLabel={(miraclesvideoOptions) => miraclesvideoOptions.description}
        />

      </div>
      <YouTube videoId={miraclesselectedVideo} opts={opts} />
      </div> 
    </div>)

}
export default Miracles;