import Select from 'react-select';
import React, { useState } from 'react';
import YouTube from 'react-youtube';
//import { useRef } from "react";

const Chants = () => {
 
  const chantvideoOptions = [
    {
      id: 'p_shhU_H5Z0',
      description: 'Gregorian Chant Rosary',
    },
    {
      id: 'yKeRc6qFfck',
      description: 'Gregorian Chants Honor and Praise God',
    },
    {
      id: '7EG2oiF2tow',
      description: 'Gregorian Chants From A Monastery',
    },
    {
      id: 'z2gN4GHhp2w',
      description: 'Sacred Gregorian Chants',
    },
    {
      id: 'Wy3xa8asXwI',
      description: 'Gregorian Chants For Reverence And Worship',
    },
    // Add more video options here 
  ];
  
  
  const [chantselectedVideo, setchantSelectedVideo] = useState(chantvideoOptions[0].id);

  const handlechantVideoChange = (event) => {


    const selectedVideoId = event.id;
    setchantSelectedVideo(selectedVideoId);
  };

  // const renderAfterCalled = useRef(false);
   const opts = {
    height: '300', // or a percentage value (e.g., '100%')
    width: '390', // or a percentage value (e.g., '100%')
    playerVars: {
      autoplay: 0,
    },
  };

;


    return (
       <div>
 
        <br />
      <b><u> <p>Select a gregorian chant</p></u></b>
      {/* Chants */}
      <div className='card'>
      <div className="centered" >
        <Select
          menuPlacement="auto"
          menuPosition="fixed"
          className="basic-single"
          classNamePrefix="select"
          defaultValue={chantvideoOptions[0]}
          isSearchable={true}
          name="Video"
          options={chantvideoOptions}
          onChange={handlechantVideoChange}
          getOptionValue={(chantvideoOptions) => chantvideoOptions.id}
          getOptionLabel={(chantvideoOptions) => chantvideoOptions.description}
        />

      </div>
      <YouTube videoId={chantselectedVideo} opts={opts} />

      </div>
    </div>)

}
export default Chants;