import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from "react";
export function NotFound(){
const navigate = useNavigate();
useEffect(() => {
setTimeout(()=>{
navigate("/")
},3000)
},[navigate])
return React.createElement('h1', null, 'Page Not Found. Redirecting...');
}