import Select from 'react-select';
import React, { useState } from 'react';
import YouTube from 'react-youtube';
//import {useRef } from "react";

const Documentaries = () => {

  const docsvideoOptions = [
    {
      id: '1tXL8dsE4sM',
      description: 'The Three Kings - Documentary',
    },
    {
      id: '0FRU92fJO_g',
      description: 'Shroud of Turin - The New Evidence',
    },
    {
      id: 'XitSlNH4KL4',
      description: 'The Epic Journey Of Modern Pilgrims To The Holy Land',
    },
    {
      id: "6hYMLmSeEXs",
      description: "Reluctant Saint - Francis of Assisi"
  
    },
    {
      id: "7jdg5EfjXuY",
      description:"St Thomas - The Apostle of India"
  
    },
    {
      id: "PRQ8Tkazw9o",
      description: "Three New Saints everyone should know"
  
    },
    {
      id: "2Labi5pUHck",
      description: "Treasures of Heaven"
  
    },
    {
      id: "di24Sjd20Rw",
      description: "The Life of Saint Faustina"
  
    },
    {
      id: "9nz7C0Kr9OA",
      description: "Augustine Documentary"
  
    }
    // Add more video options here 
  ];
  
   //const renderAfterCalled = useRef(false);
   const opts = {
    height: '300', // or a percentage value (e.g., '100%')
    width: '390', // or a percentage value (e.g., '100%')
    playerVars: {
      autoplay: 0,
    },
  };

   

const [docsselectedVideo, setdocsSelectedVideo] = useState(docsvideoOptions[0].id);

const handledocsVideoChange = (event) => {


  const selectedVideoId = event.id;
  setdocsSelectedVideo(selectedVideoId);
};





    return (
       
    <div>
      
  <br />
      <b><u> <p>Select a documentary</p></u></b>
      <div className='card'>
      {/* Documentaries  */}
      <div className="centered">
        <Select
          menuPlacement="auto"
          menuPosition="fixed"
          className="basic-single"
          classNamePrefix="select"
          isSearchable={true}
          name="Video"
          defaultValue={docsvideoOptions[0]}
          options={docsvideoOptions}
          onChange={handledocsVideoChange}
          getOptionValue={(docsvideoOptions) => docsvideoOptions.id}
          getOptionLabel={(docsvideoOptions) => docsvideoOptions.description}
        />

      </div>
      <YouTube videoId={docsselectedVideo} opts={opts}  />
      </div>
      
    </div>) 

}
export default Documentaries;