import React from "react";

const About = () => {


    return (
    <div>
        <br/>
          <h4 className=''>Our mission is to provide/share prayers and resources.
          </h4> <br/>
         <img
                alt=""
                src="Images/sacredheart.jpg"
                width="300"
                height="300"
                className="d-inline-block  left"
                alignItems="left"
              />
      <br/>
            <br/>
    </div>)

}
export default About;