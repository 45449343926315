// VideoPicker.js
import Select from 'react-select';
import React, { useState } from 'react';
import YouTube from 'react-youtube';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
const prayervideoOptions = [
  {
    id: 'sSy77qPkgRE',
    description: 'Rosary Joyful - (Mon & Sat)',
  },
  {
    id: 'Siu8clv8ds4',
    description: 'Rosary Sorrowful - (Tues & Fri)',
  },
  {
    id: 'gmzvvtuajIk',
    description: 'Rosary Glorious - (Wed & Sun)',
  },
  {
    id: 'NXcr7YYL12o',
    description: 'Rosary Luminous - (Thur)',
  },
  {
    id: 'f9p1yJOLVso',
    description: 'Chaplet of Saint Michael',
  },
  {
    id: '5pdnzUjmkzA',
    description: 'Divine Mercy Chaplet',
  },
  {
    id: 'MwJg19DZW54',
    description: 'Angelus',
  },
  {
    id: 'v5xoc7v976g',
    description: 'Auxilium Christianorum - (Mon)',
  },
  {
    id: 'nZ5TWs84FhU',
    description: 'Auxilium Christianorum - (Tues)',
  },
  {
    id: '2w7VW-mujdw',
    description: 'Auxilium Christianorum - (Wed)',
  },
  {
    id: 'OrxvbwalDj0',
    description: 'Auxilium Christianorum - (Thur)',
  },
  {
    id: 'vSlWK9qzNHM',
    description: 'Auxilium Christianorum - (Fri)',
  },
  {
    id: 'R-0b5rp26Gc',
    description: 'Auxilium Christianorum - (Sat)',
  },
  {
    id: 'yeD8hlg6h40',
    description: 'Auxilium Christianorum - (Sun)',
  },
  {
    id: 'mVM0Mn0AgHw',
    description: 'Stations of the Cross',
  },
  // Add more video options here
];





const CatholicResources = () => {
 
  const opts = {
    height: '300', // or a percentage value (e.g., '100%')
    width: '390', // or a percentage value (e.g., '100%')
    playerVars: {
      autoplay: 0,
    },
  };

  const [prayerselectedVideo, setprayerSelectedVideo] = useState(prayervideoOptions[0].id);

  const handleprayerVideoChange = (event) => {


    const selectedVideoId = event.id;
    setprayerSelectedVideo(selectedVideoId);
  };





  return (

    <div>
      <br />
      <i><b><u class="link-primary"  > <h3 class="link-warning" style={{ font: "yellow", fontFamily: "cursive" }}>Catholic Prayers & Resources</h3></u></b></i>

      <img
                alt=""
                src="Images/stmichael.jpg"
                width="390"
                height="200"
                className="d-inline-block  left"
                alignItems="left"
              />
              <br/>
      <b><u><p >Online Resources</p></u></b>
     
      <Link class="link-warning" to="https://www.ewtn.com" target="_blank" rel="noopener noreferrer">EWTN</Link>
      <br/>
      <Link class="link-warning" to="https://www.usccb.org/" target="_blank" rel="noopener noreferrer">USCCB</Link>
      <br />
      <Link class="link-warning" to="https://mass-online.org/daily-holy-mass-live-online/" target="_blank" rel="noopener noreferrer">Mass Online</Link>
      <br/>
      <Link class="link-warning" to="https://www.latinmass.live/" target="_blank" rel="noopener noreferrer" >Latin Mass Online</Link>
      <br/>
      <Link class="link-warning" to="https://www.catholicexorcism.org/" target="_blank" rel="noopener noreferrer" >Saint Michael Center</Link>
      <br></br>
      <br></br>
      <b><u> <p>Select a prayer</p></u></b>
      {/* <select class="selectpicker" data-live-search="true" onChange={handleVideoChange} value={selectedVideo}>
          {videoOptions.map((video) => (
            <option key={video.id} value={video.id}>
              {video.description}
            </option>
          ))} 
        </select>
         Prayers
        */}
        <div className='card'>
      <div class="centered">
        <Select
          menuPlacement="auto"
          menuPosition="fixed"
          className="basic-single"
          classNamePrefix="select"
          defaultValue={prayervideoOptions[0]}
          isSearchable={true}
          name="Video"
          options={prayervideoOptions}
          onChange={handleprayerVideoChange}
          getOptionValue={(prayervideoOptions) => prayervideoOptions.id}
          getOptionLabel={(prayervideoOptions) => prayervideoOptions.description}
        />
      </div>
      <YouTube videoId={prayerselectedVideo} opts={opts}/>
        </div>
        </div>

)
};

export default CatholicResources;