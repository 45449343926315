// App.js
import React from 'react';
import CatholicResources from './Components/CatholicResources';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from './Components/NotFound';
import About from './Components/About';
import Chants from './Components/Chants';
import Miracles from './Components/Miracles';
import Documentaries from './Components/Documentaries';

const App = () => {

  return (

    <div className="App">
      <Navbar className="bg-secondary.bg-gradient" style={{backgroundColor : "black"}} expand="lg">
        <Container>
          <Nav className="justify-content-start " style={{ width: "100%" }}>
            <Navbar.Brand className="" href="/">
              <img
                alt=""
                src="Images/favicon.ico"
                width="30"
                height="30"
                className="d-inline-block  left"
                alignItems="left"
              />{' '}
              <u className="link-warning"  ><b className="link-primary">Catholics Prayers</b></u>
            </Navbar.Brand>
            <Navbar.Brand className="right ms-auto" href="/chants"><u className="link-warning"  ><b className="link-primary">Gregorian Chants</b></u></Navbar.Brand>
            <Navbar.Brand className="right ms-auto" href="/miracles"><u className="link-warning"  ><b className="link-primary">Miracles</b></u></Navbar.Brand>
            <Navbar.Brand className="right ms-auto" href="/documentaries"><u className="link-warning"  ><b className="link-primary">Documentaries</b></u></Navbar.Brand>
            <Navbar.Brand className="right ms-auto" href="/about"><u className="link-warning"  ><b className="link-primary">About</b></u></Navbar.Brand>
          </Nav>
        </Container>
      </Navbar>
      <Routes>
        <Route path ="/" element={
         
        <div className="container text-center card" style={{ display: 'flex', justifyContent: 'auto',  alignItems: 'center', background: "linear-gradient(115deg, #004ff9, #fff94c)" }} >
          <CatholicResources />
          
        </div>
      }>
      </Route>
      
      <Route path='/chants' element={ 
      <div className="container text-center card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "linear-gradient(115deg, #004ff9, #fff94c)" }} >
        <Chants/>
        <center><Navbar.Brand className="right ms-auto" href="/"><u className="link-warning"  ><b className="link-primary">Home</b></u></Navbar.Brand></center>
      </div>}/>


      <Route path='/miracles' element={ 
      <div className="container text-center card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "linear-gradient(115deg, #004ff9, #fff94c)" }} >
        <Miracles/>
        <center><Navbar.Brand className="right ms-auto" href="/"><u className="link-warning"  ><b className="link-primary">Home</b></u></Navbar.Brand></center>
      </div>}/>


      <Route path='/documentaries' element={ 
      <div className="container text-center card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "linear-gradient(115deg, #004ff9, #fff94c)" }} >
        <Documentaries/>
        <center><Navbar.Brand className="right ms-auto" href="/"><u className="link-warning"  ><b className="link-primary">Home</b></u></Navbar.Brand></center>
      </div>}/>


      <Route path='/about' element={ 
      <div className="container text-center card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "linear-gradient(115deg, #004ff9, #fff94c)" }} >
        <About/>
        <center><Navbar.Brand className="right ms-auto" href="/"><u className="link-warning"  ><b className="link-primary">Home</b></u></Navbar.Brand></center>
      </div>}/>
     
     
      <Route path='*' element={ <div className="container text-center card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: "linear-gradient(115deg, red, red)" }} >
         
          <NotFound/>
          
        </div>}/>
      </Routes>
    </div>

  );
};

export default App;